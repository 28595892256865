import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  DeleteCommentApi,
  memberGoalStatementListApi,
} from "src/DAL/member/member";
import { useLocation, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import AddKimsReply from "./AddKimReply";
import { project_name } from "../../config/config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { AnswerHistory } from "./AnswerHistory";
import { useState } from "react";
import { useEffect } from "react";
import { QuestionsReply } from "./QuestionsReply";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MemberGoalstatementList = () => {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [replies, setReplies] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(0);
  const [openAnswerHistory, setOpenAnswerHistory] = useState(false);
  const [answerHistoryData, setAnswerHistoryData] = useState();
  const [openReply, setOpenReply] = useState(false);
  const [replyData, setReplyData] = useState();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenAnswerDrawer = (value) => {
    setAnswerHistoryData(value);
    setOpenAnswerHistory(true);
  };

  const handleCloseAnswerDrawer = () => {
    setOpenAnswerHistory(false);
  };

  const handleOpenReplyDrawer = (value) => {
    setReplyData(value);
    setOpenReply(true);
  };
  const handleCloseReplyDrawer = () => {
    setOpenReply(false);
  };

  const goalDetail = async () => {
    const result = await memberGoalStatementListApi(params.id);
    if (result.code == 200) {
      setGallery(result.goal_statement_gallery);
      setQuestions(result.goal_statement_question);
      setReplies(result.reply);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteCommentApi(deleteDoc._id);
    if (result.code === 200) {
      goalDetail();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    goalDetail();
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-12 col-md-6 mb-3 reply-anchor d-flex">
          <IconButton
            className="back-screen-button mb-4 me-3"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <p className="mt-2">
            {state?.first_name &&
              state?.first_name +
                " " +
                state?.last_name +
                " (" +
                state?.email +
                ")"}
          </p>
        </div>
        <div className="col-12 col-md-6 ">
          <a
            className="small-contained-button float-end mt-1 anchor-style"
            href="#hina-reply"
          >
            Hina's Reply
          </a>
        </div>
        <div className="col-12 mb-5 ">
          {
            <>
              <h2>Goal Statement Gallery</h2>
              {gallery.length == 0 ? (
                <div className="goal-gallery p-3">
                  Member has not added any goal statement gallery yet
                </div>
              ) : (
                <ImageList sx={{ height: 300 }} cols={6} rowHeight={164}>
                  {gallery.map((item) => (
                    <ImageListItem key={item.thumbnail_1}>
                      <img
                        src={`${s3baseUrl + item.thumbnail_1}`}
                        height="100px"
                        srcSet={`${s3baseUrl + item.thumbnail_1}`}
                        alt={s3baseUrl + item.thumbnail_2}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </>
          }
        </div>
        {questions.map((question, index) => {
          return (
            <div className="col-12">
              <div className=" mb-1 card ">
                <Accordion
                  expanded={expanded === index}
                  onChange={handleChangeAccordion(index)}
                  className="question-background"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="goal-statement-question">
                      {question.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="">
                        <div className="row">
                          <div className="col-12 mb-4 text-muted">
                            {question.answer == "" ? "" : question.answer}
                          </div>
                          <div className="col-6 mb-3 text-muted text-start">
                            <button
                              className="small-contained-button"
                              onClick={() => {
                                handleOpenAnswerDrawer(question);
                              }}
                            >
                              Answer History
                            </button>
                          </div>
                          <div className="col-6 mb-3 text-muted text-end">
                            <button
                              className="small-contained-button me-auto"
                              onClick={() => handleOpenReplyDrawer(question)}
                            >
                              Reply {" (" + question.comment.length + ")"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <h2 id="hina-reply" className="mb-4">
            Hina's Reply
          </h2>
        </div>
        {replies.map((reply) => {
          return (
            <>
              <div className="col-12 mb-3">
                <CircleIcon /> {reply.message}
              </div>
              <div className="col-12 mb-1">
                {reply?.audio_file && (
                  <audio
                    className="w-100"
                    src={s3baseUrl + reply?.audio_file}
                    controls
                  />
                )}
              </div>
              <div className="col-6 mb-5 text-muted">
                {moment(reply.createdAt).format("YYYY-MM-DD")}
              </div>
              <div className="col-6 text-end">
                <button
                  className="small-contained-button"
                  onClick={() => handleAgreeDelete(reply)}
                >
                  Delete
                </button>
              </div>
            </>
          );
        })}
      </div>
      <div className="row">
        <div className="col-12">
          <AddKimsReply goalDetail={goalDetail} />
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={openAnswerHistory}
        onOpenDrawer={handleOpenAnswerDrawer}
        onCloseDrawer={handleCloseAnswerDrawer}
        pageTitle={`Answer History`}
        componentToPassDown={
          <AnswerHistory
            data={answerHistoryData}
            member_id={params.id}
            detailQuestionHistory={goalDetail}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={openReply}
        onOpenDrawer={handleOpenReplyDrawer}
        onCloseDrawer={handleCloseReplyDrawer}
        pageTitle={`Replies`}
        name={state?.name}
        componentToPassDown={
          <QuestionsReply
            data={replyData}
            detailQuestionHistory={goalDetail}
            onCloseDrawer={handleCloseReplyDrawer}
          />
        }
      />
    </div>
  );
};

export default MemberGoalstatementList;

import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeZones } from "src/utils/constant";
import countryList from "react-select-country-list";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import { countries } from "src/utils/country";
import { AddMemberApi, EditMemberApi } from "src/DAL/member/member";
import { consultantListing } from "src/DAL/consultant/consultant";
import { s3baseUrl } from "src/config/config";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditMember() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [consultantValue, setConsultantValue] = React.useState([]);
  const [oldImage, setOldImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [selectCountry, setSelectCountry] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = React.useState();
  const [inputValue, setInputValue] = React.useState("");

  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "",
    member_status: "",
    street: "",
    city: "",
    zip_code: "",
    state: "",
    consultant_id: "",
    first_payment_date: "",
    member_biography: "",
    image: {},
    short_description: "",
    time_zone: "",
    memberShipPurchase: "",
    is_membership_purchase: "",
  });
  const changeHandlerCountry = (value) => {
    console.log(value.target.value, "asdfghjkl");
    setSelectCountry(value.target.value);
  };
  const getNavItemList = async () => {
    const result = await menuList();
    if (result.code === 200) {
      setMenuList(result.menus);
      //   setVaultName(result.vault_category[0].vault_slug);
    }
  };
  console.log(state, "statestate");

  const consultantListData = async () => {
    setIsLoading(true);
    let result = await consultantListing();
    console.log(result, "lololololo");
    if (result.code == 200) {
      console.log(result.consultant_list, "result.consultant");
      setConsultantValue(result.consultant_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
    console.log(result, "okokoko");
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageStatus(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const audioFileChange = (e) => {
    console.log("clicked");
    setAudio(e.target.files[0]);
  };
  const handleProgramName = (data) => {
    setProgramName(data.program_slug);
  };
  const handleVaultName = (data) => {
    setVaultName(data.vault_slug);
  };
  const handldeDeleteAudio = () => {
    setAudio();
    console.log("reset State");
  };
  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const membersName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["members"]: membersName,
    }));
  };
  const handleChangeGroups = (event) => {
    const {
      target: { value },
    } = event;
    setGroups(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const groupName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["groups"]: groupName,
    }));
  };

  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.days < 0) {
      enqueueSnackbar("No of days must be positive number ", {
        variant: "error",
      });
    } else {
      const formData = new FormData();
      formData.append("first_name", inputs.first_name);
      formData.append("last_name", inputs.last_name);
      formData.append("member_biography", inputs.short_description);
      formData.append("email", inputs.email);
      // formData.append("password", inputs.password);
      formData.append("contact_number", inputs.contact_number);
      formData.append("status", inputs.member_status);
      formData.append("street", inputs.street);
      formData.append("city", inputs.city);
      formData.append("zip_code", inputs.zip_code);
      formData.append("state", inputs.state);
      formData.append("country", country?.code);
      formData.append("consultant_id", inputs.consultant_id);
      formData.append(
        "first_payment_date",
        date ? moment(date).format("YYYY-MM-DD") : ""
      );
      formData.append("goal_statement_status", inputs.goal_status);
      formData.append("time_zone", timeZoneValue);
      // formData.append("is_membership_purchase", inputs.memberShipPurchase);
      if (imageStatus == true) {
        formData.append("profile_image", inputs.image);
      }

      //console form data

      console.log(...formData, "form data value");

      setIsLoading(true);
      const result = await EditMemberApi(formData, state.id);
      if (result.code === 200) {
        console.log(result, "result");
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        console.log(result, "error case");
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChangeProgram = (e) => {
    setProgramName(e.target.value);
  };
  const handleChangeVault = (event) => {
    setVaultName(event.target.value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const {
    id,
    name,
    lastName,
    status,
    email,
    profile,
    program,
    goal,
    contact_number,
    street,
    city,
    zip_code,
    consultant_id,
    time_zone,
    member_biography,
    profile_image,
    first_payment_date,
    selected_country,
  } = state;

  console.log(state, "statestatestate");
  React.useEffect(() => {
    consultantListData();
  }, []);
  React.useEffect(() => {
    let findCountry = countries.find((item) => {
      if (String(item.code) === String(selected_country)) {
        return item;
      }
    });
    console.log(first_payment_date, "first_payment_date");
    if (first_payment_date == "" || first_payment_date == "Invalid date") {
      setDate(null);
    } else {
      setDate(first_payment_date);
    }

    setOldImage(profile_image);
    setSelectCountry(selected_country);
    setCountry(findCountry);
    setTimeZoneValue(time_zone);
    setInputs((inputs) => ({
      ...inputs,
      ["first_name"]: name,
      ["last_name"]: lastName,
      ["last_name"]: lastName,
      ["email"]: email,
      // ["password"]: password,
      ["contact_number"]: contact_number,
      ["member_status"]: status,
      ["goal_status"]: goal,
      ["street"]: street,
      ["city"]: city,
      ["state"]: state.state,
      ["zip_code"]: zip_code,
      ["time_zone"]: time_zone,
      ["consultant_id"]: consultant_id ? consultant_id : "",
      ["short_description"]: member_biography,
      // ["memberShipPurchase"]: state?.object?.is_membership_purchase,
    }));
  }, []);
  //   console.log(typeof audioFile, "values of audio to be send ");
  console.log(date, "setDate");
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Edit Member</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            required
            name="email"
            value={inputs.email}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            required
            name="password"
            value={inputs.password}
            onChange={handleChange}
          />
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Contact Number"
            variant="outlined"
            fullWidth
            name="contact_number"
            value={inputs.contact_number}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Membership Purchase? *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="memberShipPurchase"
              value={inputs.memberShipPurchase}
              label="Is Membership Purchase? *"
              onChange={handleChange}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div> */}

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Member Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="member_status"
              value={inputs.member_status}
              label="Member Status *"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image </p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>

        <div className="col-12">
          <h3>Address Details</h3>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Street Address"
            variant="outlined"
            fullWidth
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zip Code"
            variant="outlined"
            fullWidth
            name="zip_code"
            value={inputs.zip_code}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            fullWidth
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            value={country}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4 select-box">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                className="svg-color"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectCountry}
                name="country"
                label="Country"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                onChange={changeHandlerCountry}
              >
                {options.map((option_value, i) => {
                  return (
                    <MenuItem value={option_value.value}>
                      {option_value.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div> */}
        <Divider className="mt-2" />
        <h3 className="mt-3">Configurations</h3>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="First Payment Date"
              inputFormat="MM/dd/yyyy"
              value={date}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Sales Consultant
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.consultant_id}
              name="consultant_id"
              label="Sales Consultant"
              onChange={handleChange}
            >
              {consultantValue.map((consultant) => {
                return (
                  <MenuItem value={consultant.data._id}>
                    {consultant.data.first_name +
                      " " +
                      consultant.data.last_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Goal Statement Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="goal_status"
              value={inputs.goal_status}
              label="Goal Statement Status"
              onChange={handleChange}
            >
              <MenuItem value="false">Lock</MenuItem>
              <MenuItem value="true">Unlock</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          {/* <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Time Zone
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.time_zone}
              label="Time Zone"
              name="time_zone"
              onChange={handleChange}
            >
              {TimeZones.map((value, i) => {
                return <MenuItem value={value}>{value}</MenuItem>;
              })}
            </Select>
          </FormControl> */}
          <Autocomplete
            value={timeZoneValue}
            onChange={(event, newValue) => {
              setTimeZoneValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            renderInput={(params) => (
              <TextField {...params} label="Time Zone" />
            )}
          />
        </div>
        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description  "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>
              Member Biography (Maximum limit 500 characters)
            </FormHelperText>
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import {
  HkAffirmationIcons,
  hkDashboard,
  HkGoalStatmentIcons,
  HkGratitudeIcons,
  HkPodsIcons,
  HkProfileIcons,
  hkProgramme,
  HkQuotesIcons,
  HkVaultIcons,
  HkMarketIcons,
  HkMarketPostIcons,
  Expired,
  Pinned,
  Rejected,
  Time,
} from "src/assets";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);

const getImageIcon = (name) => (
  <img src={name} width={22} height={22} className="navbar-icon" />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getImageIcon(hkDashboard),
  },

  {
    title: "Programs",
    path: "/programs",
    icon: getImageIcon(hkProgramme),
  },
  {
    title: "Members",
    path: "/member",
    icon: getImageIcon(HkProfileIcons),
  },
  {
    title: "Consultant",
    path: "/consultant",
    icon: getImageIcon(HkProfileIcons),
  },
  {
    title: "Goal Statement",
    path: "/goal-statement",
    icon: getImageIcon(HkGoalStatmentIcons),
  },
  {
    title: "Self image",
    path: "/self-image",
    icon: getImageIcon(HkGoalStatmentIcons),
  },

  {
    title: "Marketplace",
    icon: getImageIcon(HkMarketIcons),
    child_options: [
      {
        title: "Pinned Posts",
        path: "/pinned-posts",
        icon: getImageIcon(Pinned),
      },
      {
        title: "Active Posts",
        path: "/market-place",
        icon: getImageIcon(HkMarketPostIcons),
      },
      {
        title: "Pending Posts",
        path: "/pending-posts",
        icon: getImageIcon(Time),
      },
      {
        title: "Rejected Posts",
        path: "/rejected-posts",
        icon: getImageIcon(Rejected),
      },
      {
        title: "Expired Posts",
        path: "/expired-posts",
        icon: getImageIcon(Expired),
      },
    ],
  },

  {
    title: "Hina's Recording",
    icon: getImageIcon(HkVaultIcons),
    child_options: [
      {
        title: "Recordings",
        path: "/recording-list",
        icon: getImageIcon(HkVaultIcons),
      },
      {
        title: "Categories",
        path: "/vault",
        icon: getImageIcon(HkVaultIcons),
      },
    ],
  },
  {
    title: "Study Session",
    icon: getImageIcon(hkProgramme),
    child_options: [
      {
        title: "Sessions",
        path: "/sessions",
        icon: getImageIcon(hkProgramme),
      },
      {
        title: "Session Recording",
        path: "/session-recording",
        icon: getImageIcon(hkProgramme),
      },
    ],
  },
  {
    title: "Extras",
    icon: getImageIcon(HkAffirmationIcons),
    child_options: [
      {
        title: "Quotes",
        path: "/quotesList",
        icon: getImageIcon(HkQuotesIcons),
      },
      {
        title: "Gratitude",
        path: "/gratitude",
        icon: getImageIcon(HkGratitudeIcons),
      },
      {
        title: "Affirmation",
        path: "/affirmation",
        icon: getImageIcon(HkAffirmationIcons),
      },
      {
        title: "Affirmation Categories",
        path: "/affirmations-categories",
        icon: getImageIcon(HkAffirmationIcons),
      },
    ],
  },
  {
    title: "Payments",
    icon: getImageIcon(hkDashboard),
    child_options: [
      {
        title: "Stripe Products",
        path: "/stripe-products",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Transactions",
        path: "/payment-transactions",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Payment Templates",
        path: "/payment-template",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Payment Request",
        path: "/payment-request",
        icon: getImageIcon(hkDashboard),
      },
    ],
  },
  {
    title: "Calendar",
    icon: getImageIcon(hkDashboard),
    child_options: [
      {
        title: "Groups",
        path: "/calendar-groups",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Calendar Events",
        path: "/calender-events",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Links",
        path: "/calendar-links",
        icon: getImageIcon(HkPodsIcons),
      },
    ],
  },
  {
    title: "Support",
    icon: getImageIcon(hkDashboard),
    child_options: [
      {
        title: "Departments",
        path: "/departments",
        icon: getImageIcon(hkDashboard),
      },
      {
        title: "Support Tickets",
        path: "/support-ticket",
        icon: getImageIcon(hkDashboard),
      },
    ],
  },

  {
    title: "Client Portal",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "General Setting",
        path: "/general-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Client Dashboard",
        path: "/client-dashboard",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Content Setting",
        path: "/content-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },

  {
    title: "Consultant Portal",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "General Setting",
        path: "/consultants-general-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },

  {
    title: "General Setting",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "Default Setting",
        path: "/default-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Email Setting",
        path: "/email-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Stripe Setting",
        path: "/add-stripe",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Campaign Setting",
        path: "/add-compaign",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "SendGrid Setting",
        path: "/send-grid",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Email Templates",
        path: "/email-templates",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  {
    title: "Manage Website",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "Pages",
        path: "/website-pages",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Testimonials",
        path: "/website-testimonials",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Payment Plans",
        path: "/website-general-payment-plans",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "FAQs",
        path: "/website-faq",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Meet The Team",
        path: "/website-team",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Contact Us Team",
        path: "/website-contact-team",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
];

export default sidebarConfig;

import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { addQuotesApi } from "src/DAL/quotes/Quotes";
import {
  clientSettingApi,
  updateClientDashboardSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ClientDashboardSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState("Controlled");
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [oldFile, setOldProfileImage] = React.useState();
  const [oldMeatTeamImage, setOldMeatTeamImage] = React.useState();
  const [meatTeamImage, setMeatTeamImage] = React.useState();
  const [faqImage, setFaqImage] = React.useState();
  const [oldFaqImage, setOldFaqImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [detailDescriptionCks, setDetailDescriptionCks] = React.useState("");
  const [announcementCks, setAnnouncementCks] = React.useState("");
  const [footerLogo, setFooterLogo] = React.useState();
  const [brandFavIcon, setBrandFavIcon] = React.useState();

  const [inputs, setInputs] = React.useState({
    welcomeVideo: "",
    videoUrl: "",
    image: {},
    faqImage: {},
    meatTeamImage: {},
    meatTeamLink: "",
    meatTeamText: "",
    faqText: "",
    faqLink: "",
    contactUsText: "",
    contactUsLink: "",
    anouncement_button_link: "",
    anouncement_status: true,
    anouncement_description: "",
    anouncement_button_text: "",
  });
  const clientDashboardData = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      let setting = result.content_setting.client_dashboard_setting;
      setOldProfileImage(setting.contact_us_image);
      setOldMeatTeamImage(setting.team_image);
      setOldFaqImage(setting.faq_image);
      setInputs({
        ...inputs,
        ["welcomeVideo"]: setting.video_type,
        ["videoUrl"]: setting.video_url,
        ["meatTeamLink"]: setting.team_link,
        ["meatTeamText"]: setting.team_text,
        ["faqText"]: setting.faq_text,
        ["faqLink"]: setting.faq_link,
        ["contactUsText"]: setting.contact_us_text,
        ["contactUsLink"]: setting.contact_us_link,
        ["anouncement_button_link"]: setting.anouncement_button_link,
        ["anouncement_button_text"]: setting.anouncement_button_text,
        ["anouncement_status"]: setting.anouncement_status,
        ["anouncement_description"]: setting.anouncement_description,
      });
      setIsLoading(false);
      setDetailDescriptionCks(setting?.welcome_image);
      setAnnouncementCks(setting.anouncement_description);
    }
  };
  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("width", "1000");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["image"]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fileChangedHandler2 = async (e) => {
    setMeatTeamImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("width", "1000");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["meatTeamImage"]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fileChangedHandler3 = async (e) => {
    setFaqImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("width", "1000");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["faqImage"]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      video_url: inputs.videoUrl,
      video_type: inputs.welcomeVideo,
      team_link: inputs.meatTeamLink,
      team_text: inputs.meatTeamText,
      faq_link: inputs.faqLink,
      faq_text: inputs.faqText,
      contact_us_image: file ? inputs.image : oldFile,
      faq_image: faqImage ? inputs.faqImage : oldFaqImage,
      team_image: meatTeamImage ? inputs.meatTeamImage : oldMeatTeamImage,
      contact_us_link: inputs.contactUsLink,
      anouncement_button_link: inputs.anouncement_button_link,
      anouncement_button_text: inputs.anouncement_button_text,
      anouncement_status: inputs.anouncement_status,
      anouncement_description: announcementCks,
      contact_us_text: inputs.contactUsText,
      welcome_image: detailDescriptionCks,
    };
    let data = {
      client_dashboard_setting: postData,
    };
    console.log(data, "postData");

    setIsLoading(true);
    const result = await updateClientDashboardSettingApi(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    clientDashboardData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h2>Client Dashboard</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                welcome Video
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="welcomeVideo"
                value={inputs.welcomeVideo}
                label="Welcome Video*"
                onChange={handleChange}>
                <MenuItem value="repeat">Welcome Video Repeating</MenuItem>
                <MenuItem value="once">Welcome Video For Once</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Welcome Video Url"
              variant="outlined"
              fullWidth
              required
              name="videoUrl"
              value={inputs.videoUrl}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Contact Us Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(400 X 400) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : (
                  oldFile && <img src={s3baseUrl + oldFile} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Meat Team Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(400 * 400) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {meatTeamImage ? (
                  <img src={meatTeamImage} height="50" />
                ) : (
                  oldMeatTeamImage && (
                    <img src={s3baseUrl + oldMeatTeamImage} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="meatTeamImage"
                    onChange={fileChangedHandler2}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.meatTeamImage.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.meatTeamImage.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload FAQ Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(400 * 400) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {faqImage ? (
                  <img src={faqImage} height="50" />
                ) : (
                  oldFaqImage && (
                    <img src={s3baseUrl + oldFaqImage} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file2">
                  <Input
                    accept="image/*"
                    id="contained-button-file2"
                    multiple
                    type="file"
                    name="faqImage"
                    onChange={fileChangedHandler3}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.faqImage.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.faqImage.name}</p>
            )}
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meet The Team Text"
              variant="outlined"
              fullWidth
              required
              name="meatTeamText"
              value={inputs.meatTeamText}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meet The Team Link"
              variant="outlined"
              fullWidth
              required
              name="meatTeamLink"
              value={inputs.meatTeamLink}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="FAQ Text"
              variant="outlined"
              fullWidth
              required
              name="faqText"
              value={inputs.faqText}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="FAQ Link"
              variant="outlined"
              fullWidth
              required
              name="faqLink"
              value={inputs.faqLink}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Contact US Text"
              variant="outlined"
              fullWidth
              required
              name="contactUsText"
              value={inputs.contactUsText}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Contact US Link"
              variant="outlined"
              fullWidth
              required
              name="contactUsLink"
              value={inputs.contactUsLink}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCks}
              detailDescriptionCk={detailDescriptionCks}
            />
          </div>
          <h3 className="mt-3">Dashboard Announcement</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Announcement Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="anouncement_status"
                value={inputs.anouncement_status}
                label="Announcement Status*"
                onChange={handleChange}>
                <MenuItem value={true}>On</MenuItem>
                <MenuItem value={false}>Off</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.anouncement_status == true && (
            <>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Announcement Description"
                  variant="outlined"
                  fullWidth
                  required
                  name="anouncement_description"
                  value={inputs.anouncement_description}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Announcement Button Text"
                  variant="outlined"
                  fullWidth
                  required
                  name="anouncement_button_text"
                  value={inputs.anouncement_button_text}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Announcement Button Link"
                  variant="outlined"
                  fullWidth
                  required
                  name="anouncement_button_link"
                  value={inputs.anouncement_button_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-4">
                <h4>Announcement Description</h4>
                <TinyEditor
                  setDetailDescription={setAnnouncementCks}
                  detailDescriptionCk={announcementCks}
                />
              </div>
            </>
          )}

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}

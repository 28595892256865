// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
// components
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
} from "../components/_dashboard/app";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import DashboardTable from "src/components/_dashboard/DashboardTable";
import RecentMembersTable from "src/components/_dashboard/RecentMembers";
import DashboardCalenders from "src/components/_dashboard/DashboardCalender";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import Page from "src/components/Page";
import moment from "moment";
import { difference_between_two_dates } from "src/utils/constant";
import GeneralCalendar from "src/components/GeneralCalendar";

const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DashboardApp() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [lessonCount, setLessonCount] = useState("");
  const [memberCount, setMemberCount] = useState("");
  const [programCount, setProgramCount] = useState("");
  const [programRecordingCount, setProgramRecordingCount] = useState("");
  const [recentLesson, setRecentLesson] = useState([]);
  const [generalInfo, setGeneralInfo] = useState();
  const [events, setEvents] = useState([]);
  const [inputs, setInputs] = useState({
    member_count: "",
    program_count: "",
    lesson_count: "",
    program_recording_count: "",
    recent_lesson: [],
    recent_member: [],
    events: [],
  });
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [defaultTimeZone, setDefaultTimeValue] = useState();
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);

  const getDashboard = async () => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }
    const result = await dashboardApi(apiData);
    if (result.code == 200) {
      setInputs(result);
      // setMemberCount(result.member_count);
      // setProgramCount(result.program_count);
      // setProgramRecordingCount(result.program_recording_count);
      // setRecentLesson(result.recent_lesson);
      // setRecentMember(result.recent_member);
      // setLessonCount(result.lesson_count);
      // setEvents(result.events);
      setDefaultTimeValue(result?.time_zone);
      setEvents(result?.events);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };
  const getSetting = async () => {
    const result = await defaultSettingApi();
    if (result.code == 200) {
      setGeneralInfo(result?.default_setting);
    }
  };
  console.log(inputs, "inputsinputs");
  useEffect(() => {
    getSetting();
  }, []);
  useEffect(() => {
    getDashboard();
  }, [currentDate, defaultTimeZone]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <Page title={generalInfo?.meta_title}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales total={inputs.lesson_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers members={inputs.member_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={inputs.program_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports programRecording={inputs.program_recording_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable recentMember={inputs.recent_member} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DashboardTable recentLesson={inputs.recent_lesson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {/* <DashboardCalenders
              defaultTimeValue={defaultTimeValue}
              eventsList={inputs.events}
            /> */}
            <GeneralCalendar
              eventList={inputs.events}
              setCurrentDate={setCurrentDate}
              showAddEvent={false}
              time_zone={defaultTimeZone}
              currentDate={currentDate}
              isLoading={isCalenderLoading}
              setIsLoading={setIsCalenderLoading}
              reloadList={getDashboard}
              calendar_title="Calendar"
              hideArrowsAndButtons={true}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

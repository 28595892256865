import { invokeApi } from "../../bl_libs/invokeApi";

export const selfImageListingApi = async () => {
  const requestObj = {
    path: `/api/self_image/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDetailSelfImage = async (id) => {
  const requestObj = {
    path: `/api/self_image/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddSelfImageApi = async (data) => {
  const requestObj = {
    path: `/api/self_image/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddCommentOnQuestion = async (data) => {
  const requestObj = {
    path: `/api/goal_statement_question/add_comment_on_goal_statement_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditSelfImageApi = async (data, id) => {
  const requestObj = {
    path: `/api/self_image/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteSelfImage = async (id) => {
  const requestObj = {
    path: `/api/self_image/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/CustomMUITable";
import {
  expiredDeleteApi,
  expiredListing,
  marketPlaceDeleteApi,
  marketPlaceListing,
} from "src/DAL/MarketPlace/MarketPlace";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "name", label: " Name", alignRight: false },
  { id: "action_by", label: "Added By", alignRight: false },
  { id: "postType", label: "Type", alignRight: false },
  { id: "created", label: "Expiry Date", alignRight: false },
  // { id: "is_approved", label: "Approval", alignRight: false },
  // { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ExpiredPosts() {
  const { type } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const getMarketPlace = async () => {
    setIsLoading(true);
    const postData = {
      approved: "all",
      action_by: "client",
      search_text: searchText,
      status: true,
    };
    const result = await expiredListing(postData, page, rowsPerPage);
    if (result.code == 200) {
      const data = result.post.map((record, index) => {
        const dateParts = record?.expiry_date.split("-");
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[2], 10);
        let typeOfPost = "";
        if (record?.post_type == "event") {
          typeOfPost = "Event Feature";
        }
        if (record?.post_type == "promotional") {
          typeOfPost = "Promotional Offer";
        }
        if (record?.post_type == "product") {
          typeOfPost = "Product Feature";
        }
        return {
          ...record,
          name: record?.first_name + " " + record?.last_name,
          date: record?.date,
          // time: record?.time,
          postType: typeOfPost,
          created: year + "-" + month + "-" + day,
          count: index + 1 + rowsPerPage * page,
        };
      });

      setMembersData(data);
      setTotalCount(result.post_count);
      setTotalPages(result.total_pages - 1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMarketPlace();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleAgreeDeleteAll = (value) => {
    setDeleteDoc(value);
    setOpenDeleteAll(true);
  };

  const handleDeleteAll = async () => {
    setOpenDeleteAll(false);
    const transformedArray = selected.map((item) => item._id);
    const postData = {
      deleted_array: transformedArray,
    };
    setIsLoading(true);
    const result = await expiredDeleteApi(postData);
    if (result.code === 200) {
      getMarketPlace();
      setSelected([]);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const new_array = [];
    new_array.push(deleteDoc._id);
    const postData = {
      deleted_array: new_array,
    };
    setIsLoading(true);
    const result = await expiredDeleteApi(postData);
    if (result.code === 200) {
      getMarketPlace();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  useEffect(() => {
    getMarketPlace();
  }, [rowsPerPage, page]);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDeleteAll}
        setOpen={setOpenDeleteAll}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDeleteAll}
      />
      <div className="">
        <div className="row mb-3">
          <div className="col-lg-8 col-sm-12">
            <h2>Expired Posts</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            {/*  <button
              className="small-contained-button float-end mt-1"
              onClick={handleAgreeDeleteAll}>
              Delete All
            </button>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={membersData}
              type={type}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              hideButtons={true}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
              rows_per_page_count={rowsPerPage}
            />
          </div>
          {selected.length >= 1 && (
            <div className="text-end mt-4" id="fixedbutton">
              <button
                className="small-contained-button"
                onClick={handleAgreeDeleteAll}>
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { invokeApi } from "../../bl_libs/invokeApi";

export const marketPlaceListing = async (data, page, limit) => {
  const requestObj = {
    path: `/api/post/admin?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const pinnedListing = async (data, page, limit) => {
  const requestObj = {
    path: `/api/post/pinned_post?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const expiredListing = async (data, page, limit) => {
  const requestObj = {
    path: `/api/post/expired_post?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const expiredDeleteApi = async (data) => {
  const requestObj = {
    path: `/api/post/delete_posts`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceAddApi = async (data) => {
  const requestObj = {
    path: `/api/post/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/post/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceDeleteApi = async (id) => {
  const requestObj = {
    path: `/api/post/${id}`,

    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const marketPlaceApproveApi = async (data, id) => {
  const requestObj = {
    path: `/api/post/approve/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlacePinPost = async (data) => {
  const requestObj = {
    path: `/api/post/pinning_unpinning`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { marketPlaceDeleteApi } from "src/DAL/MarketPlace/MarketPlace";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MarketPlaceDetail() {
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    title: "",
    feature_type: "",
    company_name: "",
    promotion_title: "",
    registration_link: "",
    product_link: "",
    promotion_link: "",
    event_description: "",
    status: true,
    product_description: "",
    promo_description: "",
    event_time: "00:00",
    event_date: new Date(),
    expiry_date: new Date(),
  });
  console.log(
    state,
    "stateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
  );
  const handleEdit = () => {
    if (location.pathname.includes("/pending")) {
      navigate(`/pending-posts/edit-post/${state._id}`, {
        state: state,
      });
    } else if (location.pathname.includes("/pinned")) {
      navigate(`/pinned-posts/edit-post/${state._id}`, {
        state: state,
      });
    } else {
      navigate(`/market-place/edit-post/${state._id}`, {
        state: state,
      });
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleRegister = () => {
    window.open(state?.link, "_blank");
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await marketPlaceDeleteApi(state._id);
    if (result.code === 200) {
      navigate(-1);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(state, "statestate");

    if (state.post_type && state.post_type == "event") {
      setInputs((inputs) => ({
        ...inputs,
        ["first_name"]: state?.first_name,
        ["last_name"]: state?.last_name,
        ["title"]: state?.title,
        ["feature_type"]: state?.post_type,
        ["company_name"]: state?.company_name,
        ["status"]: state?.status,
        ["registration_link"]: state?.link,
        ["event_description"]: state?.short_description,
        ["event_time"]: state?.time,
        ["event_date"]: state?.date,
        ["expiry_date"]: state?.expiry,
      }));
    }
    if (state.post_type && state.post_type == "product") {
      setInputs((inputs) => ({
        ...inputs,
        ["first_name"]: state?.first_name,
        ["last_name"]: state?.last_name,
        ["title"]: state?.title,
        ["feature_type"]: state?.post_type,
        ["company_name"]: state?.company_name,
        ["status"]: state?.status,
        ["product_link"]: state?.link,
        ["product_description"]: state?.short_description,
        ["expiry_date"]: state?.expiry_date,
      }));
    }
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Post Detail</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-11 col-md-6 col-sm-12 mt-3">
          <div>
            <p className="mb-0">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}>
                {`Presented By: ${state?.name}`}
              </Typography>
            </p>{" "}
            {state?.company_name && (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}>
                {state?.company_name}
              </Typography>
            )}
            <Typography
              sx={{ color: "#1a93a9", fontSize: "25px", fontWeight: "bold" }}>
              {state?.title}
            </Typography>{" "}
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: state?.short_description,
                }}></div>
              {state?.post_type == "event" && (
                <p className="mb-0">
                  <span className="section-text">Time: </span>
                  {state?.time} ({state?.timezone})
                  <span className="section-text ms-3">Date: </span>{" "}
                  {state?.date}
                </p>
              )}
              {state?.expiry_date && (
                <span className="d-flex">
                  <p style={{ marginRight: "20px" }}>
                    <span className="section-text">Expiry: </span>
                    {/* {moment(state.expiry_date).format("YYYY-MM-DD")} */}
                    {state.expiry}
                  </p>
                  <Tooltip title="This post will be removed after the specified expiry date">
                    <Icon
                      width={20}
                      icon="bi:info-lg"
                      className="mb-1 info-icon"
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>

          <button
            className="small-contained-button mb-2"
            onClick={handleRegister}>
            {state?.button_text}
          </button>

          {state?.is_approved === "rejected" && (
            <div className="d-flex">
              <h3>Rejected Reason: </h3>
              <p className="ms-2">{state?.reject_reason}</p>
            </div>
          )}
        </div>
        <div className="col-1">
          <CustomPopoverSection menu={MENU_OPTIONS} />
        </div>
        <hr className="market-divider"></hr>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from 'notistack';

const PGIModeContext = React.createContext();

export const usePGIMode = () => useContext(PGIModeContext);
export function ContextPGIMode({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [mode, setMode] = React.useState(false);
  const [profileNewImage, setProfileNewImage] = React.useState();
  const [sideBarLogo, setSideBarLogo] = React.useState();
  const [favIcon, setFavIcon] = React.useState();
  const [metaName, setMetaName] = React.useState();
  const [logo, setLogo] = React.useState();
  const [data, setData] = React.useState();

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */

  const handleChangeMode = (value) => {
    //console.log(value, "change mode");
    setMode(value);
    setProfileNewImage(localStorage.getItem("image"));
  };
  const handleChangeLogo = (value) => {
    setSideBarLogo(localStorage.getItem("sideBarLogo"));
    setMetaName(localStorage.getItem("name"));
    setFavIcon(localStorage.getItem("sideBarLogo"));
    //console.log(localStorage.getItem("sideBarLogo"), "okokokoko");
  };
  const handleGeneralInfo = (val) => {
    setConsultantInfo(val);
  };

  useEffect(() => {
    setProfileNewImage(localStorage.getItem("image"));
  }, [localStorage.getItem("image")]);
  // useEffect(() => {
  //   setMetaName(localStorage.getItem("name"));
  // }, [localStorage.getItem("name")]);

  useEffect(() => {
    setLogo(localStorage.getItem("sideBarLogo"));
    //console.log(localStorage.getItem("sideBarLogo"), "logoContext");
  }, [localStorage.getItem("sideBarLogo")]);

  useEffect(() => {
    handleChangeLogo();
  }, [localStorage]);

  const collection = {
    mode,
    handleChangeMode,
    profileNewImage,
    setProfileNewImage,
    setSideBarLogo,
    sideBarLogo,
    handleChangeLogo,
    metaName,
    favIcon,
    setFavIcon,
    logo,
    setLogo,
  };
  return (
    <PGIModeContext.Provider value={collection}>
      {children}
    </PGIModeContext.Provider>
  );
}

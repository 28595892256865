import React, { useContext, useEffect, useState } from "react";
import { _get_consultant_from_localStorage } from "./LocalStorage";

const CreateContentSetting = React.createContext();
// const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
// const get_user = _get_user_from_localStorage();
const get_generalInfo = _get_consultant_from_localStorage();

export const useContentSetting = () => useContext(CreateContentSetting);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  //console.log(adminTimeZone, "adminTimeZone1");
  // console.log(
  //   get_consultant,
  //   "get_consultantget_consultantget_consultantget_consultantget_consultant"
  // );
  // const [userInfo, setUserInfo] = useState(get_user);
  const [generalInfo, setGeneralInfo] = useState(get_generalInfo);

  // const [contentSettingData, setContentSettingData] = useState(content_setting);
  // const [projectInfo, setProjectInfo] = useState(get_project_info);
  // const [dashboardEventData, setDashboardEventData] = useState(
  //   get_home_event_localStorage
  // );
  // const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
  //   get_testmonial_localStorage
  // );
  // const [dashboardSettingData, setDashboardSettingData] = useState(
  //   client_dashboard_setting
  // );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  // const handleContentSettingData = (val) => {
  //   setContentSettingData(val);
  // };
  // const handleDashboardSettingData = (val) => {
  //   setDashboardSettingData(val);
  // };
  // const handleDashboardTestimonialData = (val) => {
  //   setDashboardTestimonialData(val);
  // };
  // const handleDashboardEventData = (val) => {
  //   setDashboardEventData(val);
  // };
  // const handleAdminTimeZone = (val) => {
  //   setAdminTimeZone(val);
  // };
  // const handleSetUserInfo = (val) => {
  //   setUserInfo(val);
  // };
  const handleGeneralInfo = (val) => {
    console.log(val, "general info");
    setGeneralInfo(val);
  };

  //Getting Project Info from API
  // const getProjectInfo = async () => {
  //   const result = "";
  //   if (result.code === 200) {
  //     localStorage.setItem(
  //       `project_info`,
  //       JSON.stringify(result.member_setting)
  //     );
  //     setProjectInfo(result.member_setting);
  //   }
  // };

  //console.log(adminTimeZone, "adminTimeZoneadminTimeZone");
  const collection = {
    // adminTimeZone,
    // setAdminTimeZone,
    // projectInfo,
    // dashboardEventData,
    // contentSettingData,
    // dashboardSettingData,
    // dashboardTestimonialData,
    // handleContentSettingData,
    // handleDashboardSettingData,
    // handleDashboardTestimonialData,
    // handleSetUserInfo,
    // userInfo,
    // setUserInfo,
    // consultantInfo,
    // handleConsultantInfo,
    // handleAdminTimeZone,
    handleGeneralInfo,
  };

  // useEffect(() => {
  //   getProjectInfo();
  // }, []);
  //console.log(consultantInfo, "consultantInfo");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}

import React from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import { useSnackbar } from "notistack";
export default function MarketPlaceFilter({
  onCloseDrawer,
  dataList,
  status,
  approval,
  added,
  setStatus,
  setAddedBy,
  setApproval,
  setClearAll,
  dates,
  setDates,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (new_value) => {
    if (new_value === "filter") {
      if (dates.start_date && dates.end_date) {
        dataList();
        onCloseDrawer();
      } else {
        if (!dates.start_date && !dates.end_date) {
          dataList();
          onCloseDrawer();
        } else {
          if (!dates.start_date) {
            enqueueSnackbar("Please Select Start Date", { variant: "error" });
          } else {
            enqueueSnackbar("Please Select End Date", { variant: "error" });
          }
        }
      }
    } else {
      setApproval("all");
      setStatus("all");
      setAddedBy("all");
      dataList("clear");
      onCloseDrawer();
      setDates({
        start_date: null,
        end_date: null,
      });
    }
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  const handleChangeAdded = (event) => {
    setAddedBy(event.target.value);
  };
  const handleChangeApproval = (event) => {
    setApproval(event.target.value);
  };
  const handleChangeDate = (event, date) => {
    if (date == "start") {
      setDates((prevState) => {
        return {
          ...prevState,
          start_date: event,
        };
      });
    } else {
      setDates((prevState) => {
        return {
          ...prevState,
          end_date: event,
        };
      });
    }
  };
  return (
    <div className="container px-4">
      <FormControl fullWidth className="mt-3">
        <InputLabel id="demo-multiple-name-label1">Status</InputLabel>
        <Select
          labelId="demo-multiple-name-label1"
          id="demo-multiple-name"
          value={status}
          onChange={handleChangeStatus}
          input={<OutlinedInput label="Status" />}>
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>InActive</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth className="mt-3">
        <InputLabel id="demo-multiple-name-label1">Added By</InputLabel>
        <Select
          labelId="demo-multiple-name-label1"
          id="demo-multiple-name"
          value={added}
          onChange={handleChangeAdded}
          input={<OutlinedInput label="Added By" />}>
          <MenuItem value="admin">Added By Admin</MenuItem>
          <MenuItem value="client">Added By Client</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>{" "}
      <FormControl fullWidth className="mt-3">
        <InputLabel id="demo-multiple-name-label1">Approval</InputLabel>
        <Select
          labelId="demo-multiple-name-label1"
          id="demo-multiple-name"
          value={approval}
          onChange={handleChangeApproval}
          input={<OutlinedInput label="Approval" />}>
          <MenuItem value="approved">Approved</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>
      <div className="filter">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            name="start_date"
            value={dates.start_date}
            format="YYYY-MM-DD"
            onChange={(e) => handleChangeDate(e, "start")}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginTop: 2 }}
                helperText="Based on Created At"
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            name="end_date"
            value={dates.end_date}
            format="YYYY-MM-DD"
            onChange={(e) => handleChangeDate(e, "end")}
            renderInput={(params) => (
              <TextField {...params} sx={{ marginTop: 2, marginLeft: 2 }} />
            )}
          />
        </LocalizationProvider>
      </div>
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={() => {
            handleSubmit("filter");
          }}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}>
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }>
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={() => {
          handleSubmit("clear");
        }}
        startIcon={<Icon icon={roundClearAll} />}>
        Clear All
      </Button>
    </div>
  );
}

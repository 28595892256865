import { FormControl, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { marketPlaceApproveApi } from "src/DAL/MarketPlace/MarketPlace";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

export default function RejectReason({
  setEditDrawerState,
  dataList,
  rejectDoc,
}) {
  const [inputs, setInputs] = useState({
    short_description: "",
  });
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenDelete(true);
  };

  const handleReject = async () => {
    setOpenDelete(false);
    const postData = {
      status: "rejected",
      reason: inputs.short_description,
    };
    const result = await marketPlaceApproveApi(postData, rejectDoc?._id);
    if (result.code === 200) {
      dataList();
      enqueueSnackbar(result.message, { variant: "success" });
      setEditDrawerState(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <div className="container">
      <div className="row mt-3">
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Reason"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
                required
              />
              {/* <FormHelperText>Maximum limit 500 characters</FormHelperText> */}
            </FormControl>
          </div>
          <div className="text-end mt-3">
            <button className="small-contained-button">
              {/* {isLoading ? "Updating..." : "Update"} */}
              Submit
            </button>
          </div>
        </form>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to reject this post ?"}
        handleAgree={handleReject}
      />
    </div>
  );
}

import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import SearchNotFound from "../../components/SearchNotFound";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { UserListHead } from "../../components/_dashboard/user";
import USERLIST from "../../_mocks_/user";
import { clientPortalUrl, s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import moment from "moment";
import Label from "src/components/Label";
import { makeStyles } from "@mui/styles";
import {
  DeleteMemberApi,
  memberListing,
  member_login_api,
} from "src/DAL/member/member";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import MemberFilter from "./MemberFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "refUser", label: "Referred User", alignRight: false },
  { id: "program", label: "Programme", alignRight: false },
  {
    id: "EventSubscriberCount",
    label: "Event Subscriber Count",
    alignRight: false,
  },
  { id: "goalStatement", label: "Goal Statement", alignRight: false },
  { id: "CreatedAt", label: "Created At", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "goal", label: "Goal", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (row) => row.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      // row.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MembersList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [selectedGoalStatus, setSelectedGoalStatus] = useState("");
  const [selectedValue, setSelectedValue] = useState("all");

  const getMemberListing = async (clearFilterStatus) => {
    setIsLoading(true);
    let dataObject = {};
    if (clearFilterStatus == true) {
      dataObject = {
        program: [],
      };
    } else {
      dataObject = {
        program: selectedProgram == undefined ? [] : selectedProgram,
      };
    }

    const dataToSend = JSON.stringify(dataObject);
    setIsLoading(true);

    const result = await memberListing(
      page,
      rowsPerPage,
      filterName,
      dataToSend,
      selectedGoalStatus
    );

    if (result.code == 200) {
      const members = result?.member?.map((member, index) => {
        return {
          ...member,
          id: member._id,
          name: member.first_name,
          lastName: member.last_name,
          status: member.status,
          email: member.email,
          profile: member.profile_image,
          program: member.program,
          goal: member.goal_statement_status,
          contact_number: member.contact_number,
          street: member.street,
          city: member.city,
          zip_code: member.zip_code,
          state: member.state,
          selectedCountry: member.country,
          consultant_id: member?.consultant?._id,
          time_zone: member.time_zone,
          member_biography: member.member_biography,
          profile_image: member.profile_image,
          first_payment_date: member.first_payment_date,
          selected_country: member.country,
          communityLevel: member.community_level,
          likeCount: member.like_count,
          gratitudeCount: member.gratitude_count,
          coinsCount: member.coins_count,
          subscriberCount: member.event_subsciber_count,
          gaolStatementCompletedStatus: member.goal_statement_completed_status,
          gaolStatementCompletedDate: member.goal_statement_completed_date,
          save_and_close_status: member.save_and_close_status,
          save_and_close_date: member.save_and_close_date,
          createdAt: member.createdAt,
          refUser: member?.affliliate,
          object: member,

          count: index + 1 + rowsPerPage * page,
        };
      });
      //console.log(members, "members updated array");

      setData(members);
      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };
  const handleSearchName = () => {
    getMemberListing();
    setFilterNameStatus(true);
  };

  const emptyRows = page > 0 && 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy));

  const isUserNotFound = filteredUsers.length === 0;

  const handleCopy = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_info?.affiliate_url_name);
    alert("ID Copied");
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeFilter = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleEdit = (value) => {
    navigate(`/member/edit-member/${value.id}`, {
      state: value,
    });
  };
  const handleEventSubscribers = (value) => {
    navigate(`/member/event-subscriber/${value.id}`, {
      state: value,
    });
  };
  const handleProfile = (value) => {
    //console.log(value, "Profile");
    navigate(`/member/member-profile/${value.id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/member/change-password/${value.id}`, {
      state: value,
    });
  };
  const handleChangeAllTransaction = (value) => {
    navigate(`/member/all-transaction/${value.id}`, {
      state: value,
    });
  };

  const handleChangeBilling = (value) => {
    navigate(`/member/view-billing-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeAccess = (value) => {
    navigate(`/member/programme-access/${value.id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/member/add-member`);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteMemberApi(deleteDoc.id);
    if (result.code === 200) {
      getMemberListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Profile",
      icon: "iconoir:profile-circled",
      handleClick: handleProfile,
    },
    // {
    //   label: "Login As Member",
    //   icon: "ant-design:lock",
    //   handleClick: LoginAsMember,
    // },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
    {
      label: "Manage Programme Access",
      icon: "ant-design:key",
      handleClick: handleChangeAccess,
    },
    {
      label: "All Transactions",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleChangeAllTransaction,
    },

    {
      label: "View Billings",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleChangeBilling,
    },
    {
      label: "Copy Referral Id",
      icon: "eva:eye-fill",
      handleClick: handleCopy,
    },
  ];

  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    getMemberListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Members</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}>
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            <button onClick={handleNavigate} className="small-contained-button">
              Add Member
            </button>
          </div>
        </div>

        <Card>
          <UserListToolbars
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onSearch={handleSearchName}
          />

          <TableContainer sx={{ minWidth: 350 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.map((row, index) => {
                  const {
                    id,
                    name,
                    lastName,
                    status,
                    email,
                    profile,
                    program,
                    goal,
                    subscriberCount,
                    gaolStatementCompletedStatus,
                    gaolStatementCompletedDate,
                    save_and_close_status,
                    save_and_close_date,
                    refUser,
                    createdAt,
                  } = row;
                  return (
                    <TableRow hover key={id}>
                      {/* tablerowIndex = (page_num * rows_per_page) + 1 */}
                      {
                        <TableCell>
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                      }
                      <TableCell>
                        <CustomPopoverSection menu={MENU_OPTIONS} data={row} />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={s3baseUrl + profile} />
                          <Typography variant="subtitle2" noWrap>
                            {name + " " + lastName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {htmlDecode(email)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {refUser?.affiliate_user_info
                          ? refUser?.affiliate_user_info.first_name +
                            " " +
                            refUser?.affiliate_user_info.last_name
                          : "No Referred User"}
                        {refUser?.affiliate_url_name
                          ? "(" + refUser?.affiliate_url_name + ")"
                          : ""}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="program-exp">
                        {program.map((program) => {
                          return (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: program._id.title + ",",
                                }}></div>
                            </>
                          );
                        })}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="modalIcon"
                        onClick={() => handleEventSubscribers(row)}>
                        {subscriberCount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {gaolStatementCompletedStatus == true &&
                        save_and_close_status == true
                          ? "Responded"
                          : gaolStatementCompletedStatus == true &&
                            save_and_close_status == false
                          ? "Complete"
                          : gaolStatementCompletedStatus == false &&
                            save_and_close_status == false
                          ? "Incomplete"
                          : ""}
                        {gaolStatementCompletedStatus == true &&
                        save_and_close_status == true
                          ? "(" +
                            moment(save_and_close_date).format("DD-MM-YYYY") +
                            ")"
                          : gaolStatementCompletedStatus == true &&
                            save_and_close_status == false
                          ? gaolStatementCompletedDate !== "" &&
                            " (" +
                              moment(gaolStatementCompletedDate).format(
                                "DD-MM-YYYY"
                              ) +
                              ")"
                          : ""}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="program-exp">
                        {moment(createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={goal === false ? "error" : "success"}>
                          {goal === false ? "Locked" : "Unlocked"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberFilter
            value={selectedValue}
            setSelectedProgram={setSelectedProgram}
            selectedProgram={selectedProgram}
            setSelectedGoalStatus={setSelectedGoalStatus}
            selectedGoalStatus={selectedGoalStatus}
            setSelectedValue={setSelectedValue}
            handleChange={handleChangeFilter}
            dataList={getMemberListing}
            onCloseDrawer={handleCloseFilterDrawer}
          />
        }
      />
    </>
  );
}

import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
//

import { login } from "../DAL/Login/Login";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { useContentSetting } from "src/Hooks/ContentSettingState";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const {
    mode,
    handleChangeMode,
    handleChangeLogo,
    setProfileNewImage,
    profileNewImage,
    setSideBarLogo,
    setLogo,
    setFavIcon,
    // setMetaDescription,
  } = usePGIMode();
  const { handleGeneralInfo } = useContentSetting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const defaultSetting = async () => {
    const result = await defaultSettingApi();
    if (result.code == 200) {
      localStorage.setItem("generalInformation", result?.default_setting);
      localStorage.setItem("sideBarLogo", result?.default_setting?.admin_logo);
      localStorage.setItem(
        "metaDescription",
        result?.default_setting?.meta_description
      );
      localStorage.setItem("metaTitle", result?.default_setting?.meta_title);
      localStorage.setItem("favIcon", result?.default_setting?.admin_favicon);
      setLogo(result?.default_setting?.admin_logo);
      setFavIcon(result?.default_setting?.admin_favicon);
      handleGeneralInfo(result?.default_setting);

      // setMetaDescription(result?.default_setting?.meta_title);
    }
  };

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    console.log(data, "get login data");
    const result = await login(data);
    console.log(result, "login result");
    if (result.code === 200) {
      console.log(result, "login ok");
      const websiteLogo = localStorage.setItem(
        "image",
        result.adminUser.image.thumbnail_1
      );
      setSideBarLogo(
        localStorage.setItem("image", result.adminUser.image.thumbnail_1)
      );
      localStorage.setItem("token", result.token);
      localStorage.setItem("email", result.adminUser.email);
      localStorage.setItem("is_send_code", result.adminUser.is_send_code);
      localStorage.setItem(
        "is_verified_code",
        result.adminUser.is_verified_code
      );
      localStorage.setItem(
        "verification_code",
        result.adminUser.verification_code
      );
      localStorage.setItem("name", result.adminUser.name);
      localStorage.setItem("_id", result.adminUser._id);
      localStorage.setItem("image", result.adminUser.image.thumbnail_1);
      handleChangeLogo();
      defaultSetting();
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Admin
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              {/* <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link> */}
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

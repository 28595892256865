import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { programmeListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { programmeAccessApi } from "src/DAL/member/member";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ManageProgrammeAccess() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [program, setProgram] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [newDate, setNewDate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [update, setUpdate] = React.useState(false);
  const { state } = useLocation();

  // console.log(state, "statestate");

  const addDays = (object) => {
    if (
      state.first_payment_date == "Invalid date" ||
      state.first_payment_date == "" ||
      state.first_payment_date == null
    ) {
      var result = new Date();
      result.setDate(result.getDate() + object.no_of_limited_days);
      // console.log(result, "result new date if Case");
      return result;
    } else {
      var result = new Date(state.first_payment_date);
      result.setDate(result.getDate() + object.no_of_limited_days);
      return result;
    }
  };

  const programsList = async () => {
    setIsLoading(true);
    const result = await programmeListing();
    if (result.code == 200) {
      // var programArray = [];
      // result.program.map((item) => {
      //   item.expiry = addDays(item);

      //   programArray.push(item);
      // });

      var programArray = result.program.map((item) => {
        const matchingProgram = state.program.find(
          (stateItem) => stateItem?._id?._id === item?._id
        );

        // item.expiry = addDays(item);
        if (matchingProgram) {
          console.log(matchingProgram, "matchingProgrammatchingProgram");
          if (matchingProgram.expiry_date) {
            item.expiry = matchingProgram?.expiry_date;
          } else {
            if (
              state.first_payment_date == "Invalid date" ||
              state.first_payment_date == "" ||
              state.first_payment_date == null
            ) {
              var result = new Date();
              result.setDate(result.getDate() + item.no_of_limited_days);
              item.expiry = result;
            } else {
              var result = new Date(state.first_payment_date);
              result.setDate(result.getDate() + item.no_of_limited_days);
              item.expiry = result;
            }
          }
        } else {
          // if (
          //   state.first_payment_date == "Invalid date" ||
          //   state.first_payment_date == "" ||
          //   state.first_payment_date == null
          // ) {
          //   var result = new Date();
          //   result.setDate(result.getDate() + item.no_of_limited_days);
          //   item.expiry = result;
          // } else {
          //   var result = new Date(state.first_payment_date);
          //   result.setDate(result.getDate() + item.no_of_limited_days);
          //   item.expiry = result;
          // }
          var result = new Date();
          result.setDate(result.getDate() + item.no_of_limited_days);
          item.expiry = result;
        }
        return item;
      });
      setProgram(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let programme = [];
    selectionModel.forEach((item) => {
      var program_object = {};
      var result = program.filter((program_item) => program_item._id == item);

      if (result[0].program_access_type === "limited") {
        const originalDate = new Date(result[0].expiry);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
        const day = originalDate.getDate().toString().padStart(2, "0");

        const formattedDate = `${year} ${month} ${day}`;
        program_object.program_id = item;
        // program_object.expiry_date = moment(result[0].expiry).format(
        //   "YYYY MM DD"
        // );
        program_object.expiry_date = formattedDate;
      } else {
        program_object.program_id = item;
      }
      programme.push(program_object);
    });

    let programObject = {
      program: programme,
    };
    const result = await programmeAccessApi(params.id, programObject);

    if (result.code == 200) {
      setIsLoading(false);
      navigate("/member");
      enqueueSnackbar("Member added against programme successfully", {
        variant: "success",
      });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getFormattedData = () => {
    const list = program.map((item, i) => {
      let expiry = item.expiry;

      state.program.forEach((prog) => {
        if (item._id === prog._id._id) {
          if (prog.expiry_date) {
            if (newDate !== "" && i == selectedIndex) {
              expiry = item.expiry;
            } else {
              expiry = prog.expiry_date;
            }
          }
        }
      });

      return {
        id: item._id,
        image: item.program_images.thumbnail_1,
        status: item.status,
        name: item.title,
        expiry: expiry,
        no_of_limited_days: item.no_of_limited_days,
        object: item,
        index: i,
      };
    });
    return list;
  };

  // Data grid values
  const columns = [
    // { field: "id", headerName: "ID", width: 70 },

    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => {
        //Adding image
        return (
          <>
            <Avatar
              className="top-avatar me-3"
              src={s3baseUrl + params.getValue(params.id, "image")}
              alt="photoURL"
            />
          </>
        );
      },
    },

    { field: "name", headerName: "Name", width: 350 },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => {
        return (
          // Making A chip with status
          <Chip
            width="140px"
            label={
              params.getValue(params.id, "status") === true
                ? "Active"
                : "Inactive"
            }
            variant="contained"
            className={
              params.getValue(params.id, "status") === true
                ? "manage-program-chip-success"
                : ""
            }
            color={
              params.getValue(params.id, "status") === true
                ? "success"
                : "error"
            }
            size="small"
          />
        );
      },
    },
    {
      field: "expiry",
      headerName: "Expire",
      width: 200,
      renderCell: (params) => {
        //Adding image
        return (
          <>
            {params.getValue(params.id, "no_of_limited_days") > 0 && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Expire"
                  inputFormat="dd-MM-yyyy"
                  className="mt-3 mb-3"
                  value={params.getValue(params.id, "expiry")}
                  onChange={(date) =>
                    handleChangeDate(date, params.getValue(params.id, "index"))
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
          </>
        );
      },
    },
  ];

  const handleChangeDate = (date, index) => {
    setUpdate(!update);

    let target_index = program[index];
    target_index.expiry = date;
    setNewDate(date);
    setSelectedIndex(index);
    setProgram(program);
    getFormattedData();
  };

  React.useEffect(() => {
    programsList();
  }, []);

  React.useEffect(() => {
    let programsArray = [];
    state?.object?.program?.map((program) => {
      programsArray.push(program?._id?._id);
    });
    setSelectionModel(programsArray);
  }, [program]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  //console.log(selectionModel, "selectionModelAll");

  return (
    <>
      <div className="col-12 mb-3 reply-anchor">
        <IconButton
          className="back-screen-button mb-4"
          onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div style={{ width: "100%" }}>
        <h2 className="mb-4">Manage Programme Access</h2>
        <DataGrid
          autoHeight
          disableColumnFilter={true}
          rows={getFormattedData()}
          columns={columns}
          pageSize={50}
          // rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          disableSelectionOnClick={true}
          selectionModel={selectionModel}
          ColumnSortedAscendingIcon={null}
          ColumnUnsortedIcon={null}
          ColumnSortedDescendingIcon={null}
          sortIcon={null}
          disableColumnMenu={true}
        />
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { hinaKhanLogo } from "src/assets";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 15,
  },
  section: {
    margin: 5,
    padding: 10,
  },
  main_section: {
    margin: 5,
    padding: 10,
    marginBottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  heading: {
    color: "#1a93a9",
    fontSize: "30px",
    marginBottom: 10,
    marginTop: 10,
    textAlign: "center",
  },
  question: {
    fontSize: "14px",
  },

  image_style: {
    height: "52px",
    width: "200px",
  },
  answer: {
    color: "#000",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: 0,
  },
});

// Create Document Component (https://react-pdf.org)

const GoalStatementQuestion = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.main_section}>
        <Image style={styles.image_style} src={hinaKhanLogo} />
      </View>
      <View>
        <Text style={styles.heading}>Goal Statement</Text>
      </View>
      {data.map((question, index) => {
        return (
          <>
            <View style={styles.section}>
              <Text style={styles.question}>
                {`Q ${index + 1}: ${question.question}`}
              </Text>
              {/* <Text style={styles.answer}> {`A : ${question.answer}`}</Text> */}
            </View>
          </>
        );
      })}
    </Page>
  </Document>
);
export default GoalStatementQuestion;

import {
  CircularProgress,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { s3baseUrl } from "src/config/config";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {
  AddCommentOnQuestion,
  editCommentOnQuestion,
} from "src/DAL/goalstatement/goalStatement";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { DeleteQuestionCommentApi } from "src/DAL/member/member";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const QuestionsReply = ({ data, detailQuestionHistory }) => {
  const params = useLocation();
  const id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [replies, setReplies] = useState([]);
  const [repliesArrays, setRepliesArray] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [editComment, setEditComment] = React.useState("");
  const [editState, setEditState] = React.useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      question_id: data._id,
      member_id: id.id,
      comment: detailDescriptionCk,
    };

    const result = await AddCommentOnQuestion(postData);
    if (result.code == 200) {
      setReplies((replies) => [...replies, result.comment_stats]);
      setDetailDescriptionCk("");
      enqueueSnackbar(result.message, { variant: "success" });
      detailQuestionHistory();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      question_id: data._id,
      member_id: id.id,
      comment: detailDescriptionCk,
    };
    const result = await editCommentOnQuestion(postData, editComment._id);
    if (result.code == 200) {
      setReplies((replies) => {
        return replies.map((reply) => {
          let reply_obj = {};
          if (reply._id == editComment._id) {
            console.log("kokokooko");
            return (reply_obj = {
              ...reply,
              comment: detailDescriptionCk,
            });
          } else {
            return reply;
          }
        });
      });
      setDetailDescriptionCk("");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      detailQuestionHistory();
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteQuestionCommentApi(deleteDoc._id);
    if (result.code === 200) {
      setReplies((replies) => {
        return replies.filter((reply) => reply._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
      detailQuestionHistory();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    setDetailDescriptionCk(event.target.value);
  };

  const handleEdit = (value) => {
    console.log(value.target, "value");
    setEditComment(value);
    setEditState(true);
    setDetailDescriptionCk(value.comment);
  };

  useEffect(() => {
    setReplies(data.comment);
  }, [data]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row answer-history">
        <div className="col-12 mt-3">
          <p className="heading">Question :</p>
          <p className="mb-4">{htmlDecode(data.question)}</p>
        </div>
        {replies?.length < 1 ? (
          <h6>No Comment</h6>
        ) : (
          <>
            <p className="heading">Comments :</p>
            {replies?.map((reply) => {
              //console.log(reply);
              return (
                <>
                  <div className="col-10">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: reply.comment,
                      }}
                    ></div>
                  </div>

                  <div className="col-2 ms-auto text-end">
                    {/* <button
                      className="small-contained-button"
                      onClick={() => handleAgreeDelete(reply)}
                    >
                      Delete
                    </button> */}
                    <CustomPopoverSection menu={MENU_OPTIONS} data={reply} />
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
      <form onSubmit={editState ? handleSubmitEdit : handleSubmit}>
        <div className="row">
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Hina's Reply"
                multiline
                rows={6}
                name="short_description"
                value={detailDescriptionCk}
                onChange={handleChange}
                required
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="col-12 mb-3 text-end">
            <button className="small-contained-button">
              {editState
                ? isLoading
                  ? "Updating"
                  : "Update"
                : isLoading
                ? "Submitting"
                : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

import {
  Stack,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  marketPlaceDeleteApi,
  marketPlaceListing,
  marketPlacePinPost,
} from "src/DAL/MarketPlace/MarketPlace";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import Label from "src/components/Label";
import { UserListHead } from "src/components/_dashboard/user";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MarketPlaceFilter from "./MarketPlaceFilter";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import moment from "moment";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  { id: "action_by", label: "Added By", alignRight: false },
  { id: "postType", label: "Type", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
  { id: "is_approved", label: "Approval", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      // ||
      // _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MarketPlace() {
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [marketList, setMarketList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const classes = useStyles();
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [status, setStatus] = useState("all");
  const [approval, setApproval] = useState("all");
  const [addedBy, setAddedBy] = useState("all");
  const [dates, setDates] = useState({
    start_date: null,
    end_date: null,
  });
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleEdit = (value) => {
    navigate(`/market-place/edit-post/${value._id}`, {
      state: value,
    });
  };
  const handleDetail = (value) => {
    navigate(`/market-place/post-detail/${value._id}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handlePin = (value) => {
    setDeleteDoc(value);
    setOpenPin(true);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = marketList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await marketPlaceDeleteApi(deleteDoc._id);
    if (result.code === 200) {
      getMarketPlace();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handlePinPost = async () => {
    setOpenPin(false);
    setIsLoading(true);
    let postData = {
      post_id: deleteDoc._id,
      action: "pinning",
    };
    const result = await marketPlacePinPost(postData);
    if (result.code === 200) {
      getMarketPlace();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };
  const handleSearchName = () => {
    getMarketPlace();
  };
  const getMarketPlace = async (clearfilter) => {
    setIsLoading(true);
    let postData = {
      approved: approval,
      action_by: addedBy,
      search_text: filterName,
      status: status,
    };
    if (clearfilter == "clear") {
      postData = {
        approved: "all",
        action_by: "all",
        search_text: filterName,
        status: "all",
      };
    } else {
      if (dates.start_date || dates.end_date) {
        const startDate = new Date(dates.start_date);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        const startDateEnd = new Date(dates.end_date);
        const yearEnd = startDateEnd.getFullYear();
        const monthEnd = String(startDateEnd.getMonth() + 1).padStart(2, "0");
        const dayEnd = String(startDateEnd.getDate()).padStart(2, "0");
        const formattedDateEnd = `${yearEnd}-${monthEnd}-${dayEnd}`;
        postData = {
          approved: approval,
          action_by: addedBy,
          search_text: filterName,
          status: status,
          start_date: formattedDate,
          end_date: formattedDateEnd,
        };
      } else {
        postData = {
          approved: approval,
          action_by: addedBy,
          search_text: filterName,
          status: status,
        };
      }
    }

    try {
      setIsLoading(true);
      const result = await marketPlaceListing(postData, page, rowsPerPage);
      if (result.code === 200) {
        const data = result.post.map((record, index) => {
          const dateParts = record?.createdAt.split("-");
          const year = parseInt(dateParts[0], 10);
          const month = parseInt(dateParts[1], 10);
          const day = parseInt(dateParts[2], 10);
          const date = new Date(year, month, day);
          let formatted = "";
          if (record.expiry_date) {
            const expiryParts = record?.expiry_date.split("-");
            const yearExpiry = parseInt(expiryParts[0], 10);
            const monthExpiry = parseInt(expiryParts[1], 10) - 1;
            const dayExpiry = parseInt(expiryParts[2], 10);
            const dateExpiry = new Date(yearExpiry, monthExpiry, dayExpiry);
            formatted = moment(dateExpiry).format("YYYY-MM-DD");
          }

          let typeOfPost = "";
          if (record?.post_type == "event") {
            typeOfPost = "Event Feature";
          }
          if (record?.post_type == "promotional") {
            typeOfPost = "Promotional Offer";
          }
          if (record?.post_type == "product") {
            typeOfPost = "Product Feature";
          }
          return {
            ...record,
            name: record?.first_name + " " + record?.last_name,
            created: year + "-" + month + "-" + day,
            postType: typeOfPost,
            expiry: formatted,
            count: index + 1 + rowsPerPage * page,
          };
        });
        setTotalCount(result.post_count);
        setMarketList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const emptyRows = page > 0 && 0;
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - marketList.length) : 0;
  const filteredUsers = applySortFilter(
    marketList,
    getComparator(order, orderBy)
  );

  useEffect(() => {
    getMarketPlace();
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "majesticons:checkbox-list-detail",
      handleClick: handleDetail,
    },
  ];
  const MENU_OPTIONS_PIN = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Pin",
      icon: "bxs:pin",
      handleClick: handlePin,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "majesticons:checkbox-list-detail",
      handleClick: handleDetail,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Active Posts</h2>
        </div>
        <div className="col-lg-6 col-sm-12 text-end">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenFilterDrawer}>
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
          <button
            onClick={() => navigate(`/market-place/add-post`)}
            className="small-contained-button">
            Add Post
          </button>
        </div>
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}></Stack>
      <Card style={{ overflowX: "auto" }}>
        <UserListToolbars
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          onSearch={handleSearchName}
        />

        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const {
                    id,
                    name,
                    status,
                    lastName,
                    // email,
                    profile,
                    goal,
                    action_by,
                    postType,
                    is_approved,
                    created,
                    time,
                  } = row;

                  return (
                    <TableRow hover key={id}>
                      <TableCell
                        align="left"
                        onClick={() => handleDetail(row)}
                        className="cursor-table">
                        {page * rowsPerPage + (i + 1)}
                        {/* {i + 1} */}
                      </TableCell>
                      <TableCell>
                        {is_approved == "approved" && status == true ? (
                          <CustomPopoverSection
                            menu={MENU_OPTIONS_PIN}
                            data={row}
                          />
                        ) : (
                          <CustomPopoverSection
                            menu={MENU_OPTIONS}
                            data={row}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => handleDetail(row)}
                        className="cursor-table">
                        {name}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {htmlDecode(email)}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {Capitalize(htmlDecode(action_by))}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Capitalize(htmlDecode(postType))}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {created}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {time}
                      </TableCell> */}

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={
                            is_approved === "rejected"
                              ? "error"
                              : is_approved === "pending"
                              ? "warning"
                              : "success"
                          }>
                          {/* {is_approved === "false" ? "Pending" : "Approved"} */}
                          {is_approved === "rejected"
                            ? "Rejected"
                            : is_approved === "pending"
                            ? "Pending"
                            : "Approved"}
                        </Label>
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openPin}
        setOpen={setOpenPin}
        title={"Are you sure you want to Pin this Post?"}
        handleAgree={handlePinPost}
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MarketPlaceFilter
            status={status}
            approval={approval}
            added={addedBy}
            dataList={getMarketPlace}
            setStatus={setStatus}
            setAddedBy={setAddedBy}
            setApproval={setApproval}
            onCloseDrawer={handleCloseFilterDrawer}
            dates={dates}
            setDates={setDates}
          />
        }
      />
    </div>
  );
}
